.tech_app_footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #004990;
    padding: 5px;
    z-index: 1000;
    color: white;
}

/* Hide footer when phone is in landscape mode */
@media screen and (max-height: 700px) and (orientation: landscape){
    .tech_app_footer{
        display: none;
    }
}