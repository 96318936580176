.text_editable{
    border: thin inset;
    padding: 3px;
    border-width: 2px;
    display: inline-block;
}

.text_readonly{
    padding: 3px;
    display: inline-block;
}

.editor_empty{
    color: #00a2e8;
}

.text_editable_with_icon {
    display: inline;
}

.text_editable_with_icon .edit-icon{
    margin-left: 5px;
    cursor: pointer;
}