.woitem{
    padding: 5px;
    margin: 5px;
}

.woitem .MuiTypography-root{
    display: inline;
}

.woitem {
    text-align: left;
    padding: 5px;
}

.woitem_header{
    margin-left: 10px;
}

.woitem_body{
    margin-left: 30px;
}

.woitem_header .MuiTypography-root{
    font-weight: bold;
}

.woitem_header .MuiTypography-root, .woitem_header a{
    margin-right: 15px;
}

.woitem_body .MuiTypography-subtitle2{
    text-decoration: underline;
    margin-right: 10px;
}

.wo_job_progress
{
	margin-right: 5px;
}

.wo_job_progress_nowork
{
	width: 8px;
    height: 8px;
	background-color: #7f2020;
	display: inline-table;
}

.wo_job_progress_labor
{
	width: 0px;
    height: 0px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid green;
	display: inline-block;
}

.wo_job_progress_travel
{
	width: 0px;
    height: 0px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid lightgreen;
	display: inline-block;
}

.special_instructions_dialog .hierarchy_instruction_label{
    font-weight: bold;
    color: black;
}

.hierarchy_instruction {
    margin-left: 20px;
}


@media only screen and (max-width: 500px) {
    .woitem_header .MuiTypography-root, .woitem_header a{
        margin-right: 5px;
    }
}

@media only screen and (min-width: 500px) {
    .special_instructions_dialog{
        min-width: 400px;
    }
}

.asset_state_color{
    font-weight: bold;
}

.asset_state_green{
    color: green;
}

.asset_state_yellow{
    color: orange;
}

.asset_state_red{
    color: red;
}

.data_label{
    font-weight: bold;
    width: 7em;
    display: inline-table;
    vertical-align: top;
}

.wo_info_container{
    flex-wrap: wrap;
}

.wo_warnings{
    background-color: #ffffcc;
    color: red;
}

.wo_warning_box{
    font-weight: bold;
    text-align: left;
}

.workorders_header{
    justify-content: center;
    align-items: center;
}

.wo_componentheader{
    justify-content: space-between;
    align-items: center;
}

.asset_edit_value .text_editable{
    @media only screen and (min-width: 500px) {
        width: 100%;
    }
}

.asset_state_option_green{
    color: green !important;
    font-weight: bold !important;
}

.asset_state_option_yellow{
    color: orange !important;
    font-weight: bold !important;
}

.asset_state_option_red{
    color: red !important;
    font-weight: bold !important;
}

.MuiAccordionSummary-root div.MuiAccordionSummary-content p{
    font-weight: bold;
}

.MuiAccordionSummary-root{
    background-color: #eeeeee !important;
}

.wo_warnings_summary {
    background-color: red !important;
    color: white !important;
}

table.times_table thead tr th:nth-child(1) {
    width: 35%;
}

table.times_table thead tr th:nth-child(2) {
    width: 35%;
}

table.times_table thead tr th:nth-child(3) {
    width: 15%;
}

table.times_table thead tr th:nth-child(4) {
    width: 15%;
}

.form_label {
    font-weight: bold;
    margin-right: 10px;
}

.form_row {
    margin-bottom: 5px;
    display: flex;
    gap: 10px;
}

.special_instruction_icon{
    margin: -10px 0px -10px 10px !important;
}

.search_box{
    text-align: right;
    margin: 10px;
    display: block;
    width: 100%;
}

.requst_po_image{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
}

.requst_po_image button {
    align-self: flex-end;
}
