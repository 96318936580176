.App {
  text-align: center;
}

.align_center{
  text-align: center;
}

.align_left{
  text-align: left;
}

.align_right{
  text-align: right;
}

.contentRoot{
  margin-bottom: 50px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.navbar-logo .small-logo{
  display: inline;
  position: absolute;
  top: 105px;
  left: 32px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.current_user_name{
  margin-top: 35px;
  margin-left: 20px;
  text-align: left;
}

button.submit_button{
  margin-top: 50px !important;
  width: 100%;
}

.MuiFormHelperText-root{
  color: red !important;
}

.nav-search{
  position: absolute !important;
  right: 45px;
}

.MuiDialogTitle-root .MuiIconButton-root{
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.app_control_space{
  margin-right: 25px !important;
}

.MuiDialogTitle-root{
  padding: 5px !important;
}

.dialog-form span{
  font-size: 16px !important;
}

.boxed_group{
  border: 1px solid #c0c0c0;
  padding: 0.35em 0.625em 0.75em;
  margin-bottom: 10px;
}

.scroll-content{
  max-height: 50%;
  overflow-y: auto;
}

@media only screen and (min-width: 600px) {
  .MuiDialog-paper{
    padding: 20px;
  }
  .main_component_container{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.componentheader{
  margin: 10px;
  display: flex;
}



.MuiAccordionDetails-root .component_loading{
  margin-left: auto;
  margin-right: auto;
}

.data_block{
  text-align: left !important;
  flex-basis: 100%;
}


.data_table tbody tr:nth-child(odd){
  background-color: #f2f2f2;
}

.data_table{
  border-collapse: collapse;
}

.data_table td, .data_table th {
  text-align: left;
}

.data_table td.data_table_checkbox{
  padding-left: 5px !important;
}

.data_table tbody td {
  vertical-align: middle;
  padding: 15px 5px 15px 5px;
  border: none;
}

.data_table tfoot td {
  text-align: center;
}

.data_table thead th, .data_table thead td {
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
  .MuiDialogContent-root{
    min-width: 450px;
  }
}

.snackbar_error .MuiSnackbarContent-root {
  background-color: #cc0000;
}

.app_modal_dialog .MuiDialog-paperWidthSm{
  max-width: 80% !important;
}

.display_flex_column{
  flex-direction: column;
}


.full_width{
  width: 100%;
}

.dialog_form_control{
  margin-top: 10px !important;
  margin-bottom: 30px !important;
}

.MuiAccordionDetails-root{
  flex-wrap: wrap;
}

.wo_app_buttons{
  margin: 10px 3px 3px 3px;
  width: calc(100% - 10px);
  display: flex;
  justify-content: flex-start;
}

.wo_app_centered_buttons{
  justify-content: space-evenly;
}

.wo_app_buttons button{
  margin-right: 5px;
}

.full_width_buttons{
  display: flex;
  flex-direction: column;
}

.data_table th.checkbox_row{
  width: 20px;
}

.flexrows{
  display: flex;
  flex-direction: column;
}

.small_icon_button{
  padding: 0px !important;
  font-size: 1rem !important;
}

.tech_app_link{
  color: #004990;
  text-decoration: underline;
  cursor: pointer;
}

select.tech_app {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
}

.warning_text {
  color: red;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .data_table{
    table-layout: fixed;
  }

  .data_table td{
    overflow: hidden;
  }
  .wo_app_buttons button{
    width: 100%;
    margin-top: 10px;
  }

  .data_table th.checkbox_row{
    width: 10%;
  }

  .data_table tbody td {
    padding-left: 2px;
  }

  .data_table td.data_table_checkbox{
    padding-left: 2px !important;
  }


  .wo_app_buttons{
    display: initial;
  }

  .data_table th.hide_on_mobile{
    display: none;
  }

}

