.task_box{
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    padding: 10px;
    text-align: left;
}

.task_label{
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    align-items: flex-start;
}

.task_checkboxes{
    padding: 5px;
}

.task_view_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.task_attachment{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.tasks_buttons{
    text-align: left;
    margin: 10px;
}

.incomplete_checkbox{
    text-align: right;
}

.task_pass span.MuiFormControlLabel-label{
    color: green;
    font-weight: bold;
}

.task_caution span.MuiFormControlLabel-label{
    color: orange;
    font-weight: bold;
}

.task_failure span.MuiFormControlLabel-label{
    color: red;
    font-weight: bold;
}

.task_na span.MuiFormControlLabel-label{
    color: black;
    font-weight: bold;
}

.requires_tech_note_indicator{
	display: inline;
}

.requires_tech_note_indicator_text{
	display: inline;
	margin-right: 20px;
	font-weight: bold;
	color: red;
}

.arrow_pointing_right{
    /*animation: arrow infinite 1s linear;*/
	display: inline;
	margin-right: 10px;
	position: relative;
	top: 5px;
    animation-name: arrow;
    animation-duration: 2s;
}

.requires_tech_note_indicator .arrow_pointing_right svg polygon {
    fill: red;
    stroke: purple;
    stroke-width: 1
}

@keyframes arrow{
    0% {left: -20px}
    10% {left: 0px}
    20% {left: -20px}
    30% {left: 0px}
    40% {left: -20px}
    50% {left: 0px}
    60% {left: -20px}
    70% {left: 0px}
    80% {left: -20px}
    100% {left: 0px}
}

.task_complete{
    text-decoration: underline;
    color: gray !important;
}

.task_highlighted{
    color: red !important;
}

.task_notes_title{
    color: #645cee;
    font-weight: bold;
    font-size: 1.64rem;
    border-bottom: thin solid blue;
    margin-bottom: 20px;
}

.task_box_header {
    background-color: #004990;
}

.task_header {
    text-align: left;
    color: white;
    font-weight: bold;
}