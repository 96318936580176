.map_container{
    display: flex;
    justify-content: center;

}

.map_container_map{
    height: 82vh;
    width: 100%;
}

.map_wo_link{
    font-weight: bold;
    font-size: 18px;
    color: #004990;
    text-decoration: underline;
    cursor: pointer;
}

.map_info_window{
    text-align: left;
}

.map_info_box{
    line-height: 1.2;
    background-color: #f2f2f2;
    color: black;
    font-size: .9em;
    margin-top: 10px;
    margin-bottom: 10px;
}

.map_info_line {
    padding: 5px 5px 5px 5px;
}

.map_info_label{
    font-weight: bold;
    width: 7em;
    display: inline-table;
    vertical-align: top;
    text-align: right;
    padding-right: 5px;
}


.map_info_data_edit
{
	border-width: 1px;
    border-style: outset;
    align-items: flex-start;
	letter-spacing: normal;
    background: -webkit-linear-gradient(top, #ffffff 0%,#e5e5e5 100%);
    margin-right: 5px;
}

.map_directions {
    text-align: right;
    color: #004990;
}

.map_directions .MuiIconButton-root {
    color: #004990;
}

.edit_location_map {
    height: 50vh;
    width: 100%;
}
