.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    padding: 25px;
    max-width: 50vh;
    margin: auto;
    margin-top: 15px;
}


.password_icon{
    cursor: pointer;
}


.MuiInputAdornment-root{
    font-size: 1.5em;
}

  