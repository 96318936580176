.parts_table{
    flex-basis: 100%;
}

.parts_search_box{
    display: inline-block;
}

@media only screen and (max-width: 600px) {
    .parts_table thead tr th:nth-child(1){
        width: 30px;
    }

    .parts_table thead tr th:nth-child(2){
        width: 60px;
    }

    .parts_table thead tr th:nth-child(3){
        width: 60px;
    }

    .parts_table thead tr th:nth-child(3) span{
        display: none;
    }

    .parts_table thead tr th:nth-child(3):after {
        content: 'Desc.';
    }

    .parts_table thead tr th:nth-child(4){
        width: 30px;
    }

    .parts_table thead tr th:nth-child(5){
        width: 35px;
    }

    .parts_table thead tr th:nth-child(6){
        width: 45px;
    }

    .parts_table thead tr th:nth-child(7){
        display: none;
    }

    .parts_table tbody tr td:nth-child(7){
        display: none;
    }
}