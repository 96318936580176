.signature_item{
    margin-top: 20px;
}

.signature_item canvas{
    border: 1px solid black;
}

.signature_item{
    text-align: left;
}

.sigCanvas{
    padding: 1px;
    margin: 1px;
}