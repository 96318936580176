.asset_assignments_table{
    flex-basis: 100%;
}

@media only screen and (max-width: 600px) {
    .asset_assignments_table thead tr th:nth-child(2){
        width: 25%;
    }

    .asset_assignments_table thead tr th:nth-child(3), .asset_assignments_table thead tr th:nth-child(4){
        width: 17%;
    }

    .asset_assignments_table thead tr th:nth-child(6){
        display: none;
    }

    .asset_assignments_table tbody tr td:nth-child(6){
        display: none;
    }
}