.odoo_loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  margin: auto;
  opacity: 0.5;
  background-color: black;
}

.spinner {
  font-size: 3em;
  opacity: 1.0;
}

.component_loading .animated{
  animation: spin_local infinite 1s linear;
} 

.odoo_loading .animated {
  animation: spin infinite 1s linear;
}

.odoo_loading .spinner {
  position: absolute;
  height: auto;
  left: 50%;
  top: 50%;
  display: inline;
}


@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg) ;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin_local {
  from {
    transform: rotate(0deg) ;
  }
  to {
    transform: rotate(360deg);
  }
}